<template>
  <div>
    <div class="profitRateResult" v-if="hasReports&&level == 'campaign'">
      <el-popover
        placement="top"
        title="经营分析"
        width="700"
        trigger="hover"
      >
        <div class="profitRateResultList">
          <el-row>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">商品名称</div>
              <div class="grid-content">
                <el-tooltip
                  :content="product_report.productName"
                  placement="top"
                  effect="dark"
                >
                  <span>{{ product_report.productName }}</span>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">产品名称</div>
              <div class="grid-content">
                <el-tooltip
                  :content="product_report.goodsName"
                  placement="top"
                  effect="dark"
                >
                  <span>{{ product_report.goodsName }}</span>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">商品域名</div>
              <div class="grid-content">
                <el-tooltip
                  :content="product_report.domain"
                  placement="top"
                  effect="dark"
                >
                  <span>{{ product_report.domain }}</span>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content  bg-purple-light">商品ID</div>
              <div class="grid-content">{{ product_report.productId }}</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">产品ID</div>
              <div class="grid-content">{{ product_report.goodsId }}</div>
            </el-col>
            <!-- </el-row>
                    <el-row> -->
            <el-col :span="3">
              <div class="grid-content  bg-purple-light">订单量</div>
              <div class="grid-content">
                {{
                  product_report.codOrderCount + product_report.onlineOrderCount
                }}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">客单价</div>
              <div class="grid-content">
                {{ product_report.amountPerCustomer }}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">ROI</div>
              <div class="grid-content">{{ product_report.roi }}</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">ROI临界点</div>
              <div class="grid-content">{{ product_report.lowestRoi }}</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">签收率</div>
              <div class="grid-content">{{ product_report.signRate }}</div>
            </el-col>
            <!-- </el-row>
                    <el-row> -->
            <el-col :span="3">
              <div class="grid-content  bg-purple-light">选品人</div>
              <div class="grid-content">{{ product_report.chooserId }}</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">优化师</div>
              <div class="grid-content">{{ product_report.aderId }}</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">采购占比</div>
              <div class="grid-content">{{ product_report.buyRate }}%</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">运费占比</div>
              <div class="grid-content">
                {{
                  product_report.logisticsRate
                    ? product_report.logisticsRate + "%"
                    : 0
                }}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">广告费占比</div>
              <div class="grid-content">
                {{ product_report.adRate ? product_report.adRate + "%" : 0 }}
              </div>
            </el-col>
            <!-- </el-row>
                    <el-row> -->
            <el-col :span="3">
              <div class="grid-content  bg-purple-light">
                <el-tooltip
                  content="三项成本占比"
                  placement="top"
                  effect="dark"
                >
                  <span>三项成本占比</span>
                </el-tooltip>
              </div>
              <div class="grid-content">
                {{
                  product_report.threeRate ? product_report.threeRate + "%" : 0
                }}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">
                <el-tooltip
                  content="其他(含退款/经营成本)"
                  placement="top"
                  effect="dark"
                >
                  <span>其他(含退款/经营成本)</span>
                </el-tooltip>
              </div>
              <div class="grid-content">{{ product_report.otherRate }}</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">经营评估</div>
              <div class="grid-content">
                <img
                  src="../../../../assets/icon/high.png"
                  alt=""
                  v-if="product_report.profitRateResult == 'HIGHT'"
                />
                <img
                  src="../../../../assets/icon/middle.png"
                  alt=""
                  v-if="product_report.profitRateResult == 'MID'"
                />
                <img
                  src="../../../../assets/icon/low.png"
                  alt=""
                  v-if="product_report.profitRateResult == 'LOW'"
                />
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">客单量</div>
              <div class="grid-content">
                {{ product_report.countPerCustomer }}
              </div>
            </el-col>
          </el-row>
        </div>
        <div slot="reference">
          <img
            src="../../../../assets/icon/high.png"
            alt=""
            v-if="product_report.profitRateResult == 'HIGHT'"
          />
          <img
            src="../../../../assets/icon/middle.png"
            alt=""
            v-if="product_report.profitRateResult == 'MID'"
          />
          <img
            src="../../../../assets/icon/low.png"
            alt=""
            v-if="product_report.profitRateResult == 'LOW'"
          />
        </div>
      </el-popover>
    </div>
    <div v-else>
      -
    </div>
  </div>
</template>

<script>
import { ProductReport } from "./ProductReport.js";
export default {
  name: "AdCellProductReport",
  props: {
    value: {
      type: Object
    },
    level: {
      type: String,
      default: "campaign"
    }
  },
  data() {
    return {
      value2: {
        row: {
          level: "adset",
          campaignPurchase: 1,
          campaignCost: 4.2,
          spend: 0,
          object_actions: 10,
          product_report: {
            productId: 265348128,
            collId: 999948741,
            orderDate: 1614787200000,
            codOrderCount: 1,
            onlineOrderCount: 1,
            aderId: 1379,
            giftCost: 0,
            firstSendQty: 3,
            secondSendQty: 0,
            platform: "facebook",
            buyPrice: 156,
            adCost: 616.48,
            orderAmount: 922.92,
            id: "facebook_20210301_20210331_999948741_265348128_1379",
            areaId: 1,
            goodsId: 525795,
            goodsName: "JL-男士棉麻哈伦束脚裤",
            productName: "525795#綿麻サルエルパンツ",
            category: "休闲裤",
            categoryId: 110201,
            productType: "A",
            currencyId: 3,
            chooserId: 32408,
            designerId: 88,
            domain: "www.aibydy.com/men/bottoms/265348128",
            orgCode: "101302",
            createTime: 1614964019000,
            updateTime: 1614964019000,
            invalid: 0,
            collType: 5,
            lowPrice: 0,
            isChange: 0,
            isTestProduct: 0,
            codSignRate: 0,
            onlineSignRate: 0,
            preSignRate: 95.34,
            onlineLogisticsFee: 93.17,
            codLogisticsFee: 89.52,
            otherRate: 8,
            highLine: 8,
            lowLine: 3,
            amountPerCustomer: 461.46,
            countPerCustomer: 1.5,
            roi: 1.5,
            buyRate: 16.9,
            adRate: 66.8,
            logisticsRate: 19.79,
            threeRate: 103.49,
            hisSignRate: 0,
            signRate: 95.34,
            profitRate: -16.15,
            profitRateResult: "LOW",
            lowestRoi: 2.34
          }
        }
      }
    };
  },
  computed: {
    product_report() {
      if (this.value.row.level == "campaign") {
        return this.value.row.product_report||this.value2.row.product_report||{}
      } else {
        if (
          this.value.row.product_report &&
          this.value.row.campaignPurchase &&
          this.value.row.campaignCost
        ) {
          let purchase =
            this.value.row.object_actions ||
            this.value.row.purchase ||
            this.value.row.purchase_web;
          return new ProductReport(
            this.value.row.product_report,
            this.value.row.campaignPurchase,
            purchase,
            this.value.row.campaignCost,
            this.value.row.spend
          );
        } else {
          return {};
        }
      }
    },
    hasReports(){
      return Object.keys(this.product_report).length?true:false;
    }
  },
};
</script>

<style lang="scss">
.profitRateResultList {
  .el-row {
    border: 0.5px solid #ddd;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    line-height: 40px;
    padding: 0 10px;
    border: 0.5px solid #ddd;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    &.lh {
      line-height: 20px;
      overflow: inherit;
      text-overflow: visible;
      white-space: pre-wrap;
    }
    // &:hover{
    //     text-overflow: visible;
    //     // animation: 3s siderbar linear infinite normal;
    //     overflow:inherit;
    // }
    @keyframes siderbar {
      0% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
      }
      100% {
        transform: translateX(-45px);
        -webkit-transform: translateX(-45px);
      }
    }
  }
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}
.profitRateResult {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>